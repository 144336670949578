import React from "react";
import classes from "../Services.module.scss";

const TrafficLights = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Traffic Lights & Controllers</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/traffic.jpeg")}
            alt="Traffic light"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we specialize in providing high-quality
          traffic lights for cities, municipalities, and transportation
          authorities. Our team of experts has extensive experience in
          designing, installing, and maintaining traffic signal systems that are
          reliable, efficient, and cost-effective. We're dedicated to providing
          customized lighting solutions that meet the specific needs and
          requirements of our clients.
          <br />
          <br />
          <h4>Traffic Signal Solutions</h4>
          Traffic signals are an essential part of any transportation system, as
          they help to regulate the flow of traffic, reduce congestion, and
          improve safety for pedestrians and motorists. Our traffic signal
          solutions are designed to meet the specific needs and requirements of
          cities, municipalities, and transportation authorities, and are
          tailored to meet the regulations and guidelines set by local and
          national authorities.
          <br />
          <br />
          <h4>LED Traffic Signal Solutions</h4>
          We specialize in LED traffic signal solutions that are
          energy-efficient, cost-effective, and long-lasting. LED lighting is a
          smart choice for traffic signal systems, as it uses significantly less
          energy than traditional lighting solutions and lasts much longer. Our
          team of experts will work closely with you to design and install an
          LED traffic signal system that meets your specific needs and
          preferences.
          <br />
          <br />
          <h4>Signal Design and Installation</h4>
          At Eagle Eye Worldwide, we believe that signal design and installation
          is an essential part of any traffic signal system. That's why we offer
          comprehensive signal design and installation services to help you
          create a safe, reliable, and efficient traffic signal system. Our team
          of experts will work closely with you to create a customized signal
          plan that meets your specific requirements, while ensuring that your
          system is energy-efficient and cost-effective.
          <br />
          <br />
          <h4>Signal Maintenance and Repair</h4>
          Regular maintenance and repair of your traffic signal system is
          essential to ensure that it continues to operate safely and
          efficiently. At Eagle Eye Worldwide, we provide comprehensive signal
          maintenance and repair services for cities, municipalities, and
          transportation authorities. Our team of experts will work closely with
          you to identify any issues with your signal system and provide you
          with a customized repair plan that meets your specific needs.
          <br />
          <br />
          We offer a range of pricing and packages to fit your budget and needs.
          Our packages include everything you need to get started, including
          signal design and planning, installation, and maintenance. We also
          offer customization options so that you can tailor your project to
          meet your specific needs and preferences.
        </div>
      </div>
    </div>
  );
};

export default TrafficLights;
