import React from "react";
import classes from "../Services.module.scss";

const ConstructionofVilla = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Construction of Villa</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/villa.png")}
            alt="Smart Home"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we specialize in the construction of
          high-quality villas that are tailored to meet the unique needs and
          preferences of our clients. Our team of experienced architects,
          engineers, and builders work together to design and construct
          beautiful and functional villas that are built to last.
          <br />
          <br />
          <h4>Design</h4>
          Our team of architects will work with you to design a villa that meets
          your specific needs and preferences. We take into account factors such
          as the size of the lot, the local building codes, and your budget to
          create a design that is both functional and beautiful.
          <br />
          <br />
          <h4> Engineering</h4>
          Our team of engineers will ensure that the villa is structurally sound
          and meets all local building codes and regulations. We use the latest
          technology and materials to ensure that your villa is built to last.
          <br />
          <br />
          <h4>Construction</h4>
          Our team of builders will construct your villa with the utmost care
          and attention to detail. We use only the highest-quality materials and
          the latest construction techniques to ensure that your villa is built
          to the highest standards of quality.
          <br />
          <br />
          <h4>Project Management</h4>
          We provide full project management services to ensure that your villa
          is completed on time and within budget. Our project managers will work
          closely with you throughout the construction process to ensure that
          your vision for your villa is realized.
          <br />
          <br />
          At Eagle Eye Worldwide, we are committed to providing our clients with
          the highest level of service and quality in villa construction. Our
          goal is to exceed your expectations and provide you with a villa that
          you can be proud of for years to come.
        </div>
      </div>
    </div>
  );
};

export default ConstructionofVilla;
