import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import classes from './Loader.module.scss';

const Loader = () => {
  return (
    <div className={classes.overlay}>
      <CircularProgress
        sx={{
          '& .MuiCircularProgress-circle': {
            color: '#e47e52',
          },
        }}
      />
      <h2>Loading...</h2>
    </div>
  );
};

export default Loader;
