import React from "react";
import classes from "../Services.module.scss";

const Web = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Website Development</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          At Eagle Eye Worldwide, we provide top-notch static web development
          services for businesses of all sizes and industries. Our team of
          expert developers has years of experience creating stunning,
          responsive, and functional websites that help our clients achieve
          their business goals.
          <img
            src={require("../../../Assets/Images/Services/Services/web.jpeg")}
            alt="Web"
            className={classes.mainImg}
            style={{ maxHeight: "500px" }}
          />
          <br />
          <br />
          <h4>Website Design</h4>
          Our team will work with you to create a custom design that aligns with
          your brand and vision, ensuring a unique and engaging user experience
          for your website visitors.
          <br />
          <br />
          <h4>Website Development</h4>
          Our developers use the latest technologies to create high-performance
          websites that load quickly, are easy to navigate, and are optimized
          for search engines.
          <br />
          <br />
          <h4>Responsive Design</h4>
          Our websites are designed to look great on any device, from desktop
          computers to smartphones and tablets, ensuring that your visitors have
          a seamless experience no matter how they access your site.
          <br />
          <br />
          <h4>Maintenance and Support</h4>
          We offer ongoing maintenance and support services to ensure your
          website is always up-to-date, secure, and functioning properly.
          <br />
          <br />
          <img
            src={require("../../../Assets/Images/Services/Services/web2.jpeg")}
            alt="Web Banner"
            className={classes.Img}
            style={{ maxHeight: "500px" }}
          />
          <br />
          <br />
          At Eagle Eye Worldwide, we take pride in delivering exceptional static
          web development services that help our clients grow their businesses.
          Contact us today to learn more about how we can help you achieve your
          online goals.
        </div>
      </div>
    </div>
  );
};

export default Web;
