import React from "react";
import classes from "../Services.module.scss";

const Parking = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Electric Parking Gates</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/parking.jpeg")}
            alt="Parking Gates"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we offer reliable and efficient electric
          parking gate solutions for residential and commercial properties. Our
          team of experts is dedicated to providing you with high-quality
          electric parking gates that meet your specific needs and requirements.
          <br />
          <br />
          <h4>Design and Installation</h4>
          We provide design and installation services for electric parking
          gates. Our team of experts will work with you to determine the best
          electric parking gate solution for your property and install it with
          precision and care.
          <br />
          <br />
          <h4>Maintenance and Repair</h4>
          We offer regular maintenance services for electric parking gates to
          ensure that they are functioning properly. Our maintenance services
          include cleaning, inspection, and repair of electric parking gates.
          <br />
          <br />
          <h4>Technical Support</h4>
          Our team of experts is available to provide consultation and technical
          support to help you manage your electric parking gates effectively. We
          can help you with everything from selecting the right gate technology
          to designing a comprehensive parking gate plan.
          <br />
          <br />
          At Eagle Eye Worldwide, we understand the importance of having a
          reliable and efficient electric parking gate system. That's why we
          offer comprehensive electric parking gate services that meet your
          needs and requirements. Our team of experts is here to provide you
          with the support and services you need to keep your electric parking
          gate system functioning at its best. Contact us today to learn more
          about our electric parking gate services.
        </div>
      </div>
    </div>
  );
};

export default Parking;
