import React from "react";
import classes from "../Services.module.scss";

const GymEquipments = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>
        Outdoor and Indoor Gym Equipments
      </div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/again gym.jpeg")}
            alt="Gym"
            className={classes.mainImg}
            style={{ maxHeight: "500px" }}
          />
          At Eagle Eye Worldwide, we specialize in providing high-quality gym
          equipment for commercial and residential use. We understand the
          importance of having reliable and durable equipment for your gym, and
          we're committed to providing our clients with the best products on the
          market.
          <br />
          <br />
          <h4>Wide Range of Equipment</h4>
          We offer a wide range of gym equipment to fit any budget and fitness
          level. From cardio machines like treadmills and ellipticals to
          strength training equipment like weight machines and free weights, we
          have everything you need to outfit your gym.
          <br />
          <br />
          <h4>Custom Solutions</h4>
          We believe that every gym is unique, and we offer custom solutions to
          fit your specific needs. Whether you're outfitting a small home gym or
          a large commercial fitness center, our team of experts will work with
          you to create a custom solution that fits your space, budget, and
          fitness goals.
          <br />
          <br />
          <h4>Quality and Durability</h4>
          We only work with the best manufacturers in the industry to provide
          our clients with high-quality and durable gym equipment. All of our
          products are tested and inspected to ensure that they meet our strict
          standards for quality and performance.
          <br />
          <br />
          <h4>Installation and Maintenance</h4>
          Our team of experts will handle all aspects of installation, including
          delivery, assembly, and testing to ensure that your gym equipment is
          installed correctly and functioning properly. We also offer
          maintenance services to keep your equipment in top condition and
          extend its lifespan.
          <br />
          <br />
          At Eagle Eye Worldwide, we're committed to providing our clients with
          the highest level of customer service and support. We'll work closely
          with you throughout the entire process to ensure that your gym
          equipment meets your needs and exceeds your expectations.
        </div>
      </div>
    </div>
  );
};

export default GymEquipments;
