import React from "react";
import classes from "../Services.module.scss";

const Hologram = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>3D Hologram Display</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/Hologram.jpeg")}
            alt="Lights"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we are proud to offer cutting-edge 3D hologram
          display solutions for businesses and events of all kinds. Our
          state-of-the-art technology allows you to create stunning, lifelike
          holographic displays that capture your audience's attention and leave
          a lasting impression.
          <br />
          <br />
          Our team of experts will work with you to design and install a
          customized 3D hologram display that meets your unique needs and
          specifications. Whether you're looking to showcase a new product,
          create an immersive experience for your audience, or simply stand out
          from the competition, our 3D hologram display solutions are the
          perfect choice.
          <br />
          <br />
          <h4>Design and Consulting</h4>
          Our team of experienced designers and consultants will work with you
          to create a custom 3D hologram display that meets your unique needs
          and requirements.
          <br />
          <br />
          <h4>Installation and Setup</h4>
          We provide end-to-end installation and setup services for your 3D
          hologram display, ensuring a smooth and hassle-free experience.
          <br />
          <br />
          <h4>Maintenance and Support</h4>
          Our team is always available to provide ongoing maintenance and
          support for your 3D hologram display, ensuring optimal performance and
          longevity.
          <br />
          <br />
          <h4>Training and Education</h4>
          We offer comprehensive training and education services to ensure that
          you and your team are fully equipped to operate and maintain your 3D
          hologram display.
          <br />
          <br />
          <h4>Upgrades and Enhancements</h4>
          As technology evolves, we are committed to keeping your 3D hologram
          display up-to-date with the latest upgrades and enhancements.
          <br />
          <br />
          At Eagle Eye Worldwide, we are dedicated to delivering the
          highest-quality 3D hologram display solutions, backed by unparalleled
          service and support. Contact us today to learn more about how we can
          help you take your business or event to the next level with our 3D
          hologram display technology.
        </div>
      </div>
    </div>
  );
};

export default Hologram;
