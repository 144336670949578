import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "../Containers/Home/Home";
import About from "../Containers/About/About";
import Services from "../Containers/Services/Services";
import Gallery from "../Containers/Gallery/Gallery";
import Contact from "../Containers/Contact/Contact";
import SmartHomes from "../Containers/Services/Components/SmartHomes";
import InteriorDesign from "../Containers/Services/Components/InteriorDesign";
import ElectricalEngg from "../Containers/Services/Components/ElectricalEngg";
import InOutLights from "../Containers/Services/Components/InOutLights";
import AviationLightings from "../Containers/Services/Components/AviationLights";
import TrafficLights from "../Containers/Services/Components/TrafficLights";
import SwimmingPool from "../Containers/Services/Components/SwimmingPool";
import GymEquipments from "../Containers/Services/Components/GymEquipments";
import KidsPlayGround from "../Containers/Services/Components/KidsPlay";
import GpsTracker from "../Containers/Services/Components/GpsTracker";
import FRPnWPC from "../Containers/Services/Components/FRPnWPC";
import LandscapingGardening from "../Containers/Services/Components/LandscapingGardening";
import ConstructionOfVilla from "../Containers/Services/Components/ConstructionofVilla";
import ElectronicGadgets from "../Containers/Services/Components/Electronic";
import SewageTreatment from "../Containers/Services/Components/Sewage";
import Solar from "../Containers/Services/Components/Solar";
import Parking from "../Containers/Services/Components/Parking";
import Flexi from "../Containers/Services/Components/Flexi";
import Hologram from "../Containers/Services/Components/Hologram";
import Kidsroom from "../Containers/Services/Components/KidsRoom";
import FireNSafety from "../Containers/Services/Components/FireNSafety";
import Web from "../Containers/Services/Components/Web";

const Router = () => {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />}>
        <Route path="smart-homes" element={<SmartHomes />} />
        <Route path="interior-design" element={<InteriorDesign />} />{" "}
        <Route path="MEP-services" element={<ElectricalEngg />} />{" "}
        <Route path="indore-outdoor-lights" element={<InOutLights />} />{" "}
        <Route path="aviation-lightings" element={<AviationLightings />} />{" "}
        <Route path="traffic-lights" element={<TrafficLights />} />{" "}
        <Route path="gps-vehicle-tracker" element={<GpsTracker />} />{" "}
        <Route path="swimming-pool-construction" element={<SwimmingPool />} />{" "}
        <Route path="gym-equipments" element={<GymEquipments />} />{" "}
        <Route path="kids-playground-equipments" element={<KidsPlayGround />} />
        <Route path="FRP-WPC" element={<FRPnWPC />} />
        <Route
          path="landscaping-gardening"
          element={<LandscapingGardening />}
        />
        <Route path="villa-construction" element={<ConstructionOfVilla />} />
        <Route path="electronic-gadgets" element={<ElectronicGadgets />} />
        <Route path="electric-parking-gates" element={<Parking />} />
        <Route path="ro-water-treatment-plant" element={<SewageTreatment />} />
        <Route path="solar-services" element={<Solar />} />
        <Route path="flexi-underground-solutions" element={<Flexi />} />
        <Route path="3d-hologram-display" element={<Hologram />} />
        <Route
          path="specialized-kids-interior-decoration"
          element={<Kidsroom />}
        />
        <Route path="website-development" element={<Web />} />
      </Route>
      {/* <Route path="/projects" element={<Projects />} /> */}

      <Route path="/gallery" element={<Gallery />} />
      <Route path="/contact" element={<Contact />} />
      <Route
        path="*"
        element={
          <div
            style={{
              position: "relative",
              top: 80,
              height: "50vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#e47e52",
            }}
          >
            <h1>Page not found...!</h1>
            <h3
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
            >
              Click here to go to home page
            </h3>
          </div>
        }
      />
    </Routes>
  );
};

export default Router;
