import React from "react";
import classes from "../Services.module.scss";

const SwimmingPool = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Swimming Pool Construction</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/sh8.jpeg")}
            alt="Swimming Pool"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we specialize in designing and constructing
          custom swimming pools for our clients. Whether you're looking for a
          relaxing backyard oasis or a state-of-the-art commercial swimming
          pool, our team of experts has the knowledge and experience to bring
          your vision to life.
          <br />
          <br />
          <h4>Custom Design</h4>
          We believe that every swimming pool should be unique and tailored to
          the specific needs and desires of the client. That's why we offer
          custom design services to ensure that your swimming pool is
          one-of-a-kind. Our team of designers will work with you to create a
          custom design that fits your style and budget.
          <br />
          <br />
          <h4>High-Quality Materials</h4>
          We only use high-quality materials in our swimming pool constructions.
          From the concrete foundation to the tiles and finishes, we use only
          the best materials to ensure that your swimming pool is built to last.
          Our team has extensive experience working with a range of materials,
          including natural stone, ceramic tiles, and more.
          <br />
          <br />
          <h4>Expert Construction</h4>
          Our team of experienced builders has extensive knowledge in swimming
          pool construction. We follow strict building standards to ensure that
          your pool is not only beautiful but also safe and functional. We
          handle all aspects of the construction process, from excavation and
          installation to finishing and clean-up.
          <br />
          <br />
          <h4>Maintenance Services</h4>
          We know that maintaining a swimming pool can be a lot of work. That's
          why we offer maintenance services to help keep your pool looking and
          functioning its best. Our team of experts will handle everything from
          cleaning and chemical treatments to equipment maintenance and repairs.
          <br />
          <br />
          At Eagle Eye Worldwide, we're committed to providing our clients with
          the highest level of customer service and support. We'll work closely
          with you throughout the entire construction process to ensure that
          your swimming pool exceeds your expectations. We pride ourselves on
          our attention to detail and commitment to customer satisfaction.
        </div>
      </div>
    </div>
  );
};

export default SwimmingPool;
