import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classes from './Services.module.scss';
import { Divider } from '@mui/material';
import UIConstants from '../../Assets/constants/Constants';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Services = () => {
  const [screenWidth, setScreenWidth] = useState(0);

  const navigate = useNavigate('');
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === '/services' ||
      location.pathname === '/services/'
    ) {
      navigate('/services/smart-homes');
    }
  }, [location]);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const closeAccordion = () => {
    setExpanded(false);
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={classes.services}>
      <div className={classes.title}>Services</div>
      <div className={classes.subText}>
        Transforming Spaces: Our Expertise in Construction and Interiors for
        Residential and Commercial Projects
      </div>
      <Divider sx={{ mb: '20px' }} />
      <div className={classes.servicesBody}>
        <div className={classes.left}>
          {screenWidth > 900 ? (
            UIConstants.homeData.map((ele, i) => {
              return (
                <div
                  className={[
                    classes.item,
                    location.pathname.split('/')[2] === ele.path &&
                      classes.currentPath,
                  ].join(' ')}
                  onClick={() => {
                    navigate(`/services/${ele.path}`);
                  }}
                >
                  {ele.name}
                  <hr />
                </div>
              );
            })
          ) : (
            <Accordion expanded={expanded} onChange={handleChange}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h4>Service List: Expand for Details</h4>
              </AccordionSummary>
              <AccordionDetails>
                {UIConstants.homeData.map((ele, i) => {
                  return (
                    <div
                      className={[
                        classes.item,
                        location.pathname.split('/')[2] === ele.path &&
                          classes.currentPath,
                      ].join(' ')}
                      onClick={() => {
                        navigate(`/services/${ele.path}`);
                        closeAccordion();
                      }}
                      style={{ color: '#0b1c48' }}
                    >
                      {ele.name}
                      <hr />
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}
        </div>
        <div className={classes.right}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Services;
