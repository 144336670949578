import React from 'react';
import classes from './Gallery.module.scss';
import { Divider } from '@mui/material';
import { ReactPhotoCollage } from 'react-photo-collage';

const Gallery = () => {
  const images = [];

  // Get all the image file names using require.context
  const imageFiles = require.context(
    '../../Assets/Images/Gallery',
    true,
    /\.(png|jpg|jpeg|gif)$/
  );
  imageFiles.keys().forEach((key) => {
    const fileName = key.split('/').pop();
    images.push({ source: require(`../../Assets/Images/Gallery/${fileName}`) });
  });

  const settings = {
    width: '800px',
    height: ['250px', '170px'],
    layout: [1, 4, 2],
    photos: images,
    showNumOfRemainingPhotos: true,
  };

  return (
    <div className={classes.gallery}>
      {' '}
      <div className={classes.title}>Gallery</div>
      <div className={classes.subText}>
        Visualize Your Project: Browse Our Gallery of Completed Works in
        Construction and Interiors for Inspiration and Ideas.
      </div>
      <Divider sx={{ mb: '20px' }} />
      <div className={classes.galleryBody}>
        <ReactPhotoCollage {...settings} />
      </div>
    </div>
  );
};

export default Gallery;
