import React from "react";
import classes from "../Services.module.scss";

const Sewage = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>
        RO Water Treatment Plant Services
      </div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/RO.jpeg")}
            alt="Sewage Treatment"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we specialize in providing high-quality RO
          water treatment plant services to meet the diverse needs of our
          clients. Our team of experienced professionals has the expertise and
          technical knowledge to design, install, and maintain RO water
          treatment plants for residential, commercial, and industrial clients.
          <br />
          <br />
          <h4>Design and Installation</h4>
          We work closely with our clients to design and install customized RO
          water treatment plants that are tailored to their specific needs. Our
          team of experts use advanced software tools and the latest technology
          to design RO water treatment plants that are efficient, reliable, and
          cost-effective.
          <br />
          <br />
          <h4>Maintenance and Repair</h4>
          Our maintenance and repair services ensure that your RO water
          treatment plant is running at optimal efficiency. We offer regular
          maintenance services to keep your system running smoothly, as well as
          repair services for any issues that may arise. We also provide
          emergency repair services to quickly address any urgent needs.
          <br />
          <br />
          <h4>Upgrades and Retrofits</h4>
          We provide upgrades and retrofits to improve the efficiency and
          performance of your existing RO water treatment plant. Our team of
          experts can assess your current system and recommend upgrades or
          retrofits that will enhance its performance and extend its lifespan.
          <br />
          <br />
          <h4>Consultation and Training</h4>
          We offer consultation services to help our clients make informed
          decisions about their RO water treatment plant needs. Our team of
          experts provides comprehensive training to ensure that your staff is
          knowledgeable and competent in operating and maintaining your RO water
          treatment plant.
          <br />
          <br />
          With our comprehensive RO water treatment plant services, you can rest
          assured that your water treatment needs are in the hands of
          experienced professionals. Contact us today to learn more about our
          services and how we can help meet your needs.
        </div>
      </div>
    </div>
  );
};

export default Sewage;
