import React from "react";
import classes from "../Services.module.scss";

const SmartHomes = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Smart Homes</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/smartHomemain.jpeg")}
            alt="Smart Home"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we offer a range of smart home services
          designed to make your home more efficient, secure, and convenient. Our
          team of experts has years of experience in designing and installing
          custom smart home systems that are tailored to your specific needs and
          budget.
          <br />
          <br />
          <h4>Smart Lighting Systems</h4>
          Our smart lighting systems offer a range of features that can enhance
          the ambiance of your home and improve your energy efficiency. Our
          systems use advanced sensors and controllers to automatically adjust
          your lighting based on your schedule and preferences, and can even
          turn off lights in rooms that are not being used to help you save on
          your energy bills. With mobile app integration, you can control your
          lighting from anywhere, at any time.
          <br />
          <br />
          <h4>Home Security Systems</h4>
          Our home security systems provide 24/7 protection for your home and
          family. Our systems use advanced sensors and cameras to detect any
          suspicious activity and alert you immediately. With mobile app
          integration, you can monitor your security system from anywhere, at
          any time, and receive real-time alerts when there is any activity in
          your home. Our systems are easy to use and can be customized to meet
          your specific needs and budget.
          <br />
          <br />
          <h4>Smart Thermostats</h4>
          Our smart thermostats offer a range of features designed to help you
          save money on your energy bills while keeping your home comfortable.
          With features such as automatic scheduling, remote control, and
          occupancy sensors, our smart thermostats can learn your preferences
          and automatically adjust your temperature settings to optimize your
          energy usage. Our systems are compatible with a range of heating and
          cooling systems, including ductless mini-split systems.
          <br />
          <br />
          <h4>Home Automation Systems</h4>
          Our home automation systems offer a range of features that can make
          your home more convenient and comfortable. With features such as voice
          control, mobile app integration, and advanced scheduling, our systems
          can automate various tasks and help you manage your home more
          efficiently. Whether you want to control your lighting, thermostat, or
          entertainment system, our home automation systems can help you do it
          all with ease.
          <br />
          <br />
          <h4>Entertainment Systems</h4>
          Our entertainment systems offer high-quality audio and video for your
          home theater or multi-room audio system. Our systems are designed to
          deliver an immersive experience, with features such as high-resolution
          displays, surround sound, and voice control. With mobile app
          integration, you can control your entertainment system from anywhere,
          at any time, and enjoy your favorite movies, TV shows, and music with
          ease.
          <br />
          <br />
          <img
            src={require("../../../Assets/Images/Services/Services/smart-homes.jpg")}
            alt="Smart Home Details"
            className={classes.Img}
          />
          <br />
          <br />
          We provide comprehensive installation and support services to ensure
          that your smart home system is set up correctly and functioning
          properly. Our team of certified professionals will handle the
          installation process from start to finish, and we offer ongoing
          support, including troubleshooting and software updates, to ensure
          that your system runs smoothly over time.
          <br />
          <br />
          We offer a range of pricing and packages to fit your budget and needs.
          Our packages include everything you need to get started, including
          hardware, software, and installation. We also offer customization
          options so that you can tailor your system to meet your specific needs
          and preferences.
        </div>
      </div>
    </div>
  );
};

export default SmartHomes;
