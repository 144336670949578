import Navbar from './Containers/Navbar/Navbar';
import Router from './Router/Router';
import './App.css';
import Footer from './Containers/Footer/Footer';
import ScrollToTop from 'react-scroll-to-top';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="App">
      <Navbar />
      <div className="page-contents">
        <Router />
      </div>
      <Footer />
      <ScrollToTop smooth />
    </div>
  );
}

export default App;
