import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import classes from "./Navbar.module.scss";
import TemporaryDrawer from "../Drawer/Drawer";
import { useNavigate } from "react-router-dom";
import UIConstants from "../../Assets/constants/Constants";

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(false);

  const navigate = useNavigate();

  const handleOpenNavMenu = () => {
    setAnchorElNav(true);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(false);
  };

  return (
    <AppBar position="static" className={classes.container}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div className={classes.logoContainer}>
            <img
              src={require("../../Assets/Images/Eagle_Eye.png")}
              alt="Eagle Eye Worldwide"
              className={classes.logo}
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <TemporaryDrawer
              direction={"left"}
              open={anchorElNav}
              handleCloseNavMenu={handleCloseNavMenu}
              listItems={UIConstants.pages}
              title={
                <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href=""
                  sx={{
                    mr: 2,
                    display: { xs: "flex", md: "none" },
                    flexGrow: 1,
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                    height: 52,
                    zIndex: 9999999,
                  }}
                >
                  <img
                    src={require("../../Assets/Images/eagle eye.webp")}
                    alt="Eagle Eye Worldwide"
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </Typography>
              }
            />
          </Box>

          <Typography
            className={classes.logoMobile}
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "poppins",
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
              bacground: "white",
              // flexDirection: "column",
            }}
          >
            <img
              src={require("../../Assets/Images/Eagle_Eye.png")}
              alt="Eagle Eye Worldwide"
              onClick={() => {
                navigate("/");
              }}
            />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              ml: "50px",
            }}
          >
            {UIConstants.pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => {
                  navigate(page.path);
                  handleCloseNavMenu();
                }}
                sx={{ my: 2, color: "white", display: "block", mr: "25px" }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {UIConstants.settings.map((setting) => (
              <a
                href={setting.value}
                target="_blank"
                className="shopBtn"
                style={{
                  color: "white",
                  textDecoration: "none",
                  background: "#e47e52",
                  padding: "6px 16px",
                  borderRadius: "6px",
                  fontSize: "20px",
                }}
                rel="noreferrer"
              >
                {setting.name}
              </a>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
