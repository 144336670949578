import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function MediaCard(props) {
  const { item } = props;
  return (
    <Card sx={{ width: 278, cursor: 'pointer' }}>
      <CardMedia
        sx={{
          height: 140,
          transition: 'all 0.5s ease-in-out',
          '&:hover': {
            transform: 'scale(1.2)',
          },
        }}
        image={item.image}
        title={item.name}
      />
      <CardContent>
        <Typography
          gutterBottom
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
          }}
          component="div"
        >
          {item.name}
        </Typography>
      </CardContent>
    </Card>
  );
}
