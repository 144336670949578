import React from "react";
import classes from "../Services.module.scss";

const LandscapingGardening = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Landscaping & Gardening</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/landscaping2.jpeg")}
            alt="Landscaping"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we understand that landscaping and gardening
          are important elements in creating an aesthetically pleasing and
          functional outdoor space. We offer a range of services to help our
          clients design and maintain their landscapes and gardens.
          <br />
          <br />
          <h4>Landscape Design</h4>
          Our team of experienced landscape designers can work with you to
          create a beautiful and functional outdoor space that suits your needs
          and preferences. We take into account factors such as the climate,
          soil type, and existing vegetation to design a landscape that is
          sustainable and easy to maintain.
          <br />
          <br />
          <h4>Garden Design</h4>
          Whether you want a vegetable garden, a flower garden, or a combination
          of both, our garden design experts can help you create the perfect
          space. We take into account factors such as the amount of sunlight,
          water availability, and soil type to design a garden that thrives.
          <br />
          <br />
          <h4>Landscape and Garden Maintenance</h4>
          We offer ongoing maintenance services to ensure that your landscape
          and garden stay healthy and beautiful. Our services include pruning,
          fertilizing, weed control, and pest management.
          <br />
          <br />
          <h4>Irrigation System Installation and Maintenance</h4>
          We can design and install an irrigation system that meets the needs of
          your landscape and garden. We also offer maintenance services to
          ensure that your system is working efficiently and effectively.
          <br />
          <br />
          <h4>Vertical Gardening</h4>
          If you have limited space, but still want to enjoy the benefits of a
          garden, consider a vertical garden. Our team can design and install a
          vertical garden that makes the most of your space, while still
          providing a beautiful and functional garden.
          <br />
          <br />
          Vertical gardens are a new and innovative way to incorporate greenery
          into any space. These gardens are a great solution for those who want
          to add greenery to their homes or offices but are limited on space.
          With vertical gardens, you can add a touch of nature to any room
          without taking up much floor space.
          <br />
          <br />
          <img
            src={require("../../../Assets/Images/Services/Services/virtical2.jpeg")}
            alt="LandscapingVertical"
            className={classes.mainImg}
          />
          <br />
          <br />
          Our vertical garden services include design, installation, and
          maintenance. We work closely with our clients to create a unique and
          beautiful vertical garden that fits their needs and budget. We use
          high-quality materials to ensure that the garden is both durable and
          long-lasting.
          <br />
          <br />
          Vertical gardens not only add a beautiful and natural touch to your
          space but also have several benefits for the environment. They help to
          purify the air and reduce noise pollution. Additionally, they can help
          to regulate temperature and humidity, creating a more comfortable
          living or working environment.
          <br />
          <br />
          Whether you're looking to add a small vertical garden to your home or
          a large one to your office, our team of experts can help you create a
          stunning and sustainable green space. Contact us today to learn more
          about our vertical garden services.
          <img
            src={require("../../../Assets/Images/Services/Services/landscaping.jpg")}
            alt="LandscapingVertical"
            className={classes.mainImg}
          />
          <br />
          <br />
          At Eagle Eye Worldwide, we are committed to using sustainable and
          environmentally-friendly practices in all of our landscaping and
          gardening services. We use organic and natural materials whenever
          possible and minimize the use of pesticides and fertilizers. Our goal
          is to create beautiful outdoor spaces that are healthy and sustainable
          for both people and the environment.
        </div>
      </div>
    </div>
  );
};

export default LandscapingGardening;
