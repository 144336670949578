import React from "react";
import classes from "../Services.module.scss";
import ReactPlayer from "react-player";

const InteriorDesign = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Interior Design</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <div className={classes.video}>
            <ReactPlayer
              url={require("../../../Assets/Images/Services/videos/WhatsApp Video 2023-05-13 at 7.21.31 PM.mp4")}
              playing
              loop
              Autoplay
              muted
              width={"100%"}
              height={"100%"}
              style={{ maxWidth: "760px" }}
            />
          </div>
          <img
            src={require("../../../Assets/Images/Services/Services/interiorDesign.jpeg")}
            alt="Interior Design"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we believe that your living space should be a
          reflection of your personality and lifestyle. That's why our interior
          design services are tailored to your unique needs and preferences. Our
          team of experts will work closely with you to create a personalized
          design plan that meets your specific requirements, while ensuring that
          your living space is functional, comfortable, and stylish.
          <br />
          <br />
          <h4>Residential Interior Design</h4>
          Our residential interior design services are perfect for homeowners
          who want to create a personalized living space that meets their
          specific needs and preferences. Whether you're looking to update your
          existing space or starting from scratch, our team of experts will work
          with you to create a customized design plan that reflects your
          personality and lifestyle. We'll handle every aspect of your project,
          from design to installation, to ensure that your living space is
          exactly what you had in mind.
          <br />
          <br />
          <h4>Commercial Interior Design</h4>
          Our commercial interior design services are designed to help business
          owners create functional and aesthetically pleasing commercial spaces.
          Whether you're looking to update your office, retail space, or
          restaurant, our team of experts will work with you to create a
          customized design plan that meets your specific requirements. We'll
          handle every aspect of your project, from design to installation, to
          ensure that your commercial space is functional, comfortable, and
          stylish.
          <img
            src={require("../../../Assets/Images/Services/Services/commercial Interior design.jpeg")}
            alt="Interior Design"
            className={classes.mainImg}
          />
          <br />
          <br />
          <h4>Design Consultation</h4>
          We provide comprehensive design consultation services to help you
          create the perfect living or commercial space. Whether you're looking
          for inspiration, advice, or guidance, our team of experts is here to
          help. We'll work closely with you to understand your needs and
          preferences, and provide you with a customized design plan that meets
          your specific requirements.
          <br />
          <br />
          <h4>Design and Planning</h4>
          Our design and planning services are perfect for homeowners and
          business owners who want to ensure that their project is executed
          exactly as they envisioned it. We'll work closely with you to
          understand your needs and preferences, and create a customized design
          plan that meets your specific requirements. Our team of experts will
          provide you with a comprehensive design plan that includes everything
          from floor plans to material selections, to ensure that your project
          is exactly what you had in mind.
          <img
            src={require("../../../Assets/Images/Services/Services/furniture.jpeg")}
            alt="Furniture Design"
            className={classes.mainImg}
          />
          <br />
          <br />
          <h4>Custom Furniture Design</h4>
          We believe that your furniture should be as unique as you are. That's
          why we offer custom furniture design services that are tailored to
          your specific needs and preferences. Whether you're looking for a
          custom sofa, dining table, or bedroom set, our team of experts will
          work with you to create a design that meets your specific
          requirements.
          <br />
          <br />
          We offer a range of pricing and packages to fit your budget and needs.
          Our packages include everything you need to get started, including
          design and planning, furniture selection, and installation. We also
          offer customization options so that you can tailor your project to
          meet your specific needs and preferences.
        </div>
      </div>
    </div>
  );
};

export default InteriorDesign;
