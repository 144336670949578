import React from "react";
import classes from "../Services.module.scss";

const InOutLights = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Indoor & Outdoor Lights</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/indoor lights.jpeg")}
            alt="Lights"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we provide high-quality indoor and outdoor
          lighting solutions for both residential and commercial properties. Our
          team of experts has extensive experience in designing and installing
          lighting systems that are energy-efficient, cost-effective, and
          aesthetically pleasing. We're dedicated to providing customized
          lighting solutions that meet the specific needs and preferences of our
          clients.
          <br />
          <br />
          <h4>Indoor Lighting Solutions</h4>
          We offer a wide range of indoor lighting solutions to help you create
          a safe, comfortable, and inviting living or work space. Our team of
          experts will work closely with you to understand your lighting needs
          and preferences and create a customized lighting plan that meets your
          specific requirements. Whether you're looking for ambient lighting,
          accent lighting, or task lighting, we have the expertise and
          experience to help you achieve your goals.
          <br />
          <br />
          <h4>Outdoor Lighting Solutions</h4>
          Our outdoor lighting solutions are designed to enhance the beauty,
          safety, and security of your outdoor living spaces. Whether you're
          looking to illuminate a pathway, highlight your landscaping, or create
          a welcoming atmosphere for your guests, we have the perfect outdoor
          lighting solution for you. Our team of experts will work closely with
          you to design and install an outdoor lighting system that meets your
          specific needs and preferences.
          <img
            src={require("../../../Assets/Images/Services/Services/outdoor lights.jpeg")}
            alt="Lights"
            className={classes.mainImg}
          />
          <br />
          <br />
          <h4>LED Lighting Solutions</h4>
          We specialize in LED lighting solutions that are energy-efficient,
          cost-effective, and long-lasting. LED lighting is a smart choice for
          both residential and commercial properties, as it uses significantly
          less energy than traditional lighting solutions and lasts much longer.
          Our team of experts will work closely with you to design and install
          an LED lighting system that meets your specific needs and preferences.
          <br />
          <br />
          <h4>Lighting Maintenance and Repair</h4>
          Regular maintenance and repair of your lighting system is essential to
          ensure that it continues to operate safely and efficiently. At Eagle
          Eye Worldwide, we provide comprehensive lighting maintenance and
          repair services for both residential and commercial properties. Our
          team of experts will work closely with you to identify any issues with
          your lighting system and provide you with a customized repair plan
          that meets your specific needs.
          <br />
          <br />
          We offer a range of pricing and packages to fit your budget and needs.
          Our packages include everything you need to get started, including
          lighting design and planning, installation, and maintenance. We also
          offer customization options so that you can tailor your project to
          meet your specific needs and preferences.
        </div>
      </div>
    </div>
  );
};

export default InOutLights;
