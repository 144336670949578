import React from "react";
import classes from "../Services.module.scss";

const GpsTracker = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>GPS Tracking Devices</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/gps vehicle.jpeg")}
            alt="Vehicle Tracker"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we specialize in providing high-quality GPS
          tracker devices for vehicles and pets. Our team of experts has
          extensive experience in designing, installing, and maintaining GPS
          tracker systems that are reliable, efficient, and cost-effective.
          We're dedicated to providing customized tracking solutions that meet
          the specific needs and requirements of our clients.
          <br />
          <br />
          <h4>GPS Vehicle Tracking Solutions</h4>
          Our GPS vehicle tracking solutions are designed to help you monitor
          and manage your fleet of vehicles. Our GPS trackers provide real-time
          location tracking, speed monitoring, and fuel management, as well as
          other features to help you optimize your fleet's performance and
          reduce costs. We also offer customizable alerts to help you keep track
          of important events, such as maintenance reminders and driver behavior
          notifications.
          <br />
          <br />
          <h4>GPS Pet Tracking Solutions</h4>
          Our GPS pet tracking solutions are designed to help you keep track of
          your furry friends. Our GPS trackers provide real-time location
          tracking, activity monitoring, and customizable alerts to help you
          keep your pets safe and secure. We offer a range of pet tracking
          solutions to fit your specific needs, including wearable devices and
          collars.
          <img
            src={require("../../../Assets/Images/Services/Services/pet tracker.jpeg")}
            alt="Pet tracker"
            className={classes.mainImg}
          />
          <br />
          <br />
          <h4>Installation and Maintenance</h4>
          At Eagle Eye Worldwide, we believe that installation and maintenance
          are essential parts of any GPS tracker system. That's why we offer
          comprehensive installation and maintenance services to help you get
          the most out of your GPS tracker system. Our team of experts will work
          closely with you to ensure that your GPS tracker system is installed
          correctly and maintained properly to ensure optimal performance.
          <br />
          <br />
          We offer a range of pricing and packages to fit your budget and needs.
          Our packages include everything you need to get started, including GPS
          tracker devices, installation, and maintenance. We also offer
          customization options so that you can tailor your project to meet your
          specific needs and preferences.
        </div>
      </div>
    </div>
  );
};

export default GpsTracker;
