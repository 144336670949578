import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import classes from './Caraousel.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/fontawesome-free-solid';

const Caraousel = (props) => {
  const { list = [] } = props;
  return (
    <Carousel
      showArrows={true}
      autoPlay
      infiniteLoop
      emulateTouch
      interval={5000}
      transitionTime={800}
      showStatus={false}
      showThumbs={false}
      centerMode
      renderArrowPrev={(clickHandler, hasPrev) => {
        return (
          <div onClick={clickHandler}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{
                color: '#fff',
                fontSize: '30px',
                position: 'absolute',
                zIndex: 99,
                top: '42%',
                left: 5,
                cursor: 'pointer',
              }}
            />
          </div>
        );
      }}
      renderArrowNext={(clickHandler, hasNext) => {
        return (
          <div onClick={clickHandler} className={[classes.arrow].join(' ')}>
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{
                color: '#fff',
                fontSize: '30px',
                position: 'absolute',
                zIndex: 99,
                top: '42%',
                right: 5,
                cursor: 'pointer',
              }}
            />
          </div>
        );
      }}
    >
      {list.map((item) => {
        return (
          <div>
            <img
              src={item.image}
              alt={item.name}
              className={classes.carouselImage}
            />
          </div>
        );
      })}
    </Carousel>
  );
};

export default Caraousel;
