import React from "react";
import classes from "./About.module.scss";
import { Divider } from "@mui/material";

const About = () => {
  return (
    <div className={classes.about}>
      {" "}
      <div className={classes.title}>About Us</div>
      <div className={classes.subText}>
        Learn About Eagle Eye Worldwide: Your Trusted Partner for Exceptional
        Contracting Services.
      </div>
      <Divider sx={{ mb: "20px" }} />
      <div className={classes.serviceContainer}>
        <div className={classes.Text}>
          At Eagle Eye Worldwide, we're committed to providing exceptional
          products and services to our clients. We specialize in a wide range of
          industries, including smart home technology, interior design,
          electrical engineering, aviation obstruction lights, traffic lights,
          GPS tracking devices, swimming pool construction, villa construction,
          indoor and outdoor lights, MEP services, gym equipment (especially
          outdoor gym equipment), fire and safety equipment, auto spare parts,
          FRP and WPC products, electronic gadgets like CCTV cameras and car
          monitoring systems and Automatic car parking guidance management
          system, flexi flooring solutions, sustainable solutions in energy and
          the environment and more.
          <br />
          <br />
          Our team of experts has years of experience in their respective
          fields, and we're committed to staying up-to-date with the latest
          industry trends and technologies. We pride ourselves on our attention
          to detail, quality craftsmanship, and exceptional customer service.
          <br />
          <br />
          <h4>Our Mission</h4>
          <ul>
            <li>
              To foster strong and lasting relationships with our clients, built
              on trust, transparency, and open communication.
            </li>
            <li>
              To stay at the forefront of industry trends and emerging
              technologies, and use this knowledge to create innovative and
              cutting-edge solutions for our clients.
            </li>
            <li>
              To provide our employees with a positive and supportive work
              environment, where they can grow professionally and personally,
              and be proud of the work that they do.
            </li>
            <li>
              To make a positive impact on our local communities and the world
              at large, by embracing sustainable and environmentally responsible
              business practices, and supporting charitable causes and
              organizations.
            </li>
          </ul>
          <br />
          <br />
          <h4>Our Values</h4>
          At Eagle Eye Worldwide, we value integrity, teamwork, and excellence.
          We believe in treating our clients, employees, and partners with
          respect and fairness, and we're committed to creating a positive and
          inclusive work environment.
          <ul>
            <li>
              Integrity: We conduct ourselves with honesty, transparency, and
              ethics in everything we do. We believe in doing the right thing,
              even when it's not the easiest thing.
            </li>
            <li>
              Teamwork: We value collaboration, communication, and respect for
              others' ideas and opinions. We believe that the best solutions
              come from working together as a team.
            </li>
            <li>
              Excellence: We are committed to delivering exceptional products
              and services that exceed our clients' expectations. We strive for
              excellence in everything we do, and we are constantly looking for
              ways to improve and innovate.
            </li>{" "}
            <li>
              Professionalism: We conduct ourselves with the utmost
              professionalism, both in our interactions with clients and in the
              quality of our work. We take pride in delivering work that is of
              the highest standard.
            </li>{" "}
            <li>
              Diversity and Inclusion: We believe in creating a work environment
              that is welcoming, supportive, and inclusive for all. We value
              diversity in our team members and their perspectives, and we
              believe that this leads to better outcomes for our clients and our
              business.
            </li>{" "}
            <li>
              Customer Focus: We put our clients at the center of everything we
              do. We listen to their needs, understand their goals, and work
              tirelessly to deliver solutions that meet or exceed their
              expectations.
            </li>{" "}
            <li>
              Continuous Learning: We believe in the importance of lifelong
              learning and personal growth. We encourage our team members to
              continuously develop their skills and knowledge, and we support
              them in doing so.
            </li>
          </ul>
          <br />
          <br />
          <h4>Our Promise</h4>
          We promise to deliver exceptional products and services that meet or
          exceed our clients' expectations. We'll work closely with you
          throughout the entire process to ensure that your project is completed
          on time, within budget, and to your satisfaction.
          <br />
          <br />
          <h4>Contact Us</h4>
          Ready to learn more about Eagle Eye Worldwide and how we can help you
          achieve your goals? Contact us today to schedule a consultation. Our
          team of experts is ready to answer your questions and help you find
          the perfect solution for your project.
        </div>
      </div>
    </div>
  );
};

export default About;
