import React from "react";
import classes from "../Services.module.scss";
import PDF from "../../../Utils/PDF";

const FRPnWPC = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>
        FRP (Fiberglass Reinforced Plastic) and WPC (Wood Plastic Composite)
      </div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          At Eagle Eye Worldwide, we specialize in providing high-quality and
          innovative FRP and WPC products for a wide range of applications. Our
          team of experts has years of experience in the industry, and we're
          committed to delivering exceptional products and services to our
          clients.
          <br />
          <br />
          <h4>FRP Products</h4>
          We offer a range of FRP products, including grating, handrails,
          ladders, structural shapes, and custom fabrication. Our FRP products
          are lightweight, durable, and corrosion-resistant, making them ideal
          for harsh environments such as chemical plants, food processing
          facilities, and wastewater treatment plants.
          {/* <img
            src={require('../../../Assets/Images/Services/Services/frp.jpeg')}
            alt="FRP"
            className={classes.mainImg}
            style={{ maxHeight: '500px' }}
          /> */}
          <br />
          <br />
          <h4>WPC Products</h4>
          Our WPC products are made from a combination of wood and plastic,
          creating a strong and durable material that is resistant to moisture
          and rot. We offer a range of WPC products, including decking, fencing,
          pergolas, and custom fabrication. Our WPC products are
          low-maintenance, eco-friendly, and perfect for both indoor and outdoor
          applications.
          <img
            src={require("../../../Assets/Images/Services/Services/wrp.jpeg")}
            alt="WPC"
            className={classes.mainImg}
            style={{ maxHeight: "500px" }}
          />
          <br />
          <br />
          <h4>Custom Solutions</h4>
          We understand that every project is unique, and we offer custom
          solutions to fit your specific needs. Our team of experts will work
          with you to design and fabricate FRP or WPC products that meet your
          space, budget, and performance requirements.
          <br />
          <br />
          <h4>Installation and Maintenance</h4>
          Our team of experts will handle all aspects of installation, including
          delivery, assembly, and testing to ensure that your FRP or WPC
          products are installed correctly and functioning properly. We also
          offer maintenance services to keep your products in top condition and
          ensure their longevity.
          <br />
          <br />
          At Eagle Eye Worldwide, we're committed to providing our clients with
          the highest level of customer service and support. We'll work closely
          with you throughout the entire process to ensure that your FRP or WPC
          products meet your needs and exceed your expectations.
          <br />
          <br />
          <img
            src={require("../../../Assets/Images/Services/Services/wpc2.png")}
            alt="WPC home"
            className={classes.Img}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          float: "right",
          marginTop: "56px",
          justifyContent: "center",
        }}
      >
        <PDF
          path="EagleEye-WPC.pdf"
          name="Eagle Eye Worldwide - WPC"
          title="WPC"
        />
        <PDF
          path="EagleEye-FRP.pdf"
          name="Eagle Eye Worldwide - FRP"
          title="FRP"
        />
      </div>
    </div>
  );
};

export default FRPnWPC;
