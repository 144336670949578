import React from "react";
import classes from "../Services.module.scss";

const AviationLights = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Aviation Lights</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/aviation lights.png")}
            alt="Lights"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we specialize in providing high-quality
          aviation obstruction lights for both commercial and private airports.
          Our team of experts has extensive experience in designing, installing,
          and maintaining aviation lighting systems that are reliable,
          efficient, and cost-effective. We're dedicated to providing customized
          lighting solutions that meet the specific needs and requirements of
          our clients.
          <br />
          <br />
          <h4>Obstruction Lighting Solutions</h4>
          Obstruction lighting is an essential part of any aviation lighting
          system, as it helps to identify obstacles such as towers, buildings,
          and other structures that could be hazardous to aircraft. Our
          obstruction lighting solutions are designed to meet the specific needs
          and requirements of commercial and private airports, and are tailored
          to meet the regulations and guidelines set by the Federal Aviation
          Administration (FAA).
          <br />
          <br />
          <h4>LED Obstruction Lighting Solutions</h4>
          We specialize in LED obstruction lighting solutions that are
          energy-efficient, cost-effective, and long-lasting. LED lighting is a
          smart choice for aviation obstruction lighting systems, as it uses
          significantly less energy than traditional lighting solutions and
          lasts much longer. Our team of experts will work closely with you to
          design and install an LED obstruction lighting system that meets your
          specific needs and preferences.
          <br />
          <br />
          <h4>Lighting Design and Installation</h4>
          At Eagle Eye Worldwide, we believe that lighting is an essential part
          of any aviation lighting system. That's why we offer comprehensive
          lighting design and installation services to help you create a safe,
          reliable, and efficient aviation obstruction lighting system. Our team
          of experts will work closely with you to create a customized lighting
          plan that meets your specific requirements, while ensuring that your
          system is energy-efficient and cost-effective.
          <br />
          <br />
          <h4>Lighting Maintenance and Repair</h4>
          Regular maintenance and repair of your aviation obstruction lighting
          system is essential to ensure that it continues to operate safely and
          efficiently. At Eagle Eye Worldwide, we provide comprehensive lighting
          maintenance and repair services for commercial and private airports.
          Our team of experts will work closely with you to identify any issues
          with your lighting system and provide you with a customized repair
          plan that meets your specific needs.
          <br />
          <br />
          We offer a range of pricing and packages to fit your budget and needs.
          Our packages include everything you need to get started, including
          lighting design and planning, installation, and maintenance. We also
          offer customization options so that you can tailor your project to
          meet your specific needs and preferences.
        </div>
      </div>
    </div>
  );
};

export default AviationLights;
