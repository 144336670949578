import React from "react";
import classes from "./Home.module.scss";
import Caraousel from "../../Components/Caraousel/Caraousel";
import UIConstants from "../../Assets/constants/Constants";
import SimplePaper from "../../Components/Paper/Paper";

const Home = () => {
  return (
    <div className={classes.home}>
      <Caraousel list={UIConstants.carouselData} />
      <div className={classes.homePageText}>
        <h2>Welcome to Eagle Eye Worldwide!</h2>
        <br />
        <div>
          At Eagle Eye Worldwide, we specialize in providing innovative and
          sustainable solutions for a wide range of industries. Our team of
          experts has years of experience in designing, constructing, and
          maintaining high-quality systems and structures that meet the unique
          needs of our clients.
        </div>
        <br />

        <div>
          We offer a diverse range of services, including smart home systems,
          landscaping and gardening, construction of villas, electronic gadgets,
          solar services, biological sewage treatment, and much more. Our goal
          is to provide comprehensive solutions that help our clients save time,
          money, and energy while reducing their carbon footprint.
        </div>
      </div>
      <SimplePaper list={UIConstants.homeData} />
      <div
        className={classes.homePageText}
        style={{ backgroundColor: "#e47e52" }}
      >
        At Eagle Eye Worldwide, we pride ourselves on our commitment to customer
        satisfaction. We work closely with each of our clients to understand
        their unique needs and develop customized solutions that meet their
        goals and exceed their expectations. Our team is dedicated to providing
        exceptional service and support at every stage of the process, from
        design and construction to ongoing maintenance and support.
        <br />
        <br />
        We invite you to explore our website to learn more about our services
        and how we can help you achieve your goals. If you have any questions or
        would like to schedule a consultation, please don't hesitate to contact
        us. We look forward to hearing from you and working together to make
        your vision a reality.
      </div>
    </div>
  );
};

export default Home;
