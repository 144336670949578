import React from "react";
import classes from "../Services.module.scss";

const Electronic = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}> Electronic Gadgets</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/CCTV.png")}
            alt="cctv"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we understand the importance of security and
          safety in both residential and commercial settings. That's why we
          offer a comprehensive range of electronic gadgets and security
          solutions to keep your property and belongings safe and secure.
          <br />
          <br />
          <h4>CCTV Cameras</h4>
          We offer a variety of CCTV cameras that can be customized to meet your
          specific needs. Our cameras can be installed both indoors and outdoors
          and are designed to provide high-quality video surveillance with easy
          remote access and monitoring.
          <br />
          <br />
          <h4>Car Monitoring Systems</h4>
          We offer a wide range of car monitoring systems that are designed to
          improve the safety and security of your vehicles. Our systems can
          track your vehicle's location, speed, and other important data,
          providing you with real-time updates and alerts.
          <br />
          <br />
          <h4>Automatic Car Parking Guidance Management Systems</h4>
          Our automatic car parking guidance management systems are designed to
          make parking easier and more efficient. Our systems use advanced
          technology to guide drivers to available parking spaces, reducing
          congestion and making parking more convenient for everyone.
          <img
            src={require("../../../Assets/Images/Services/Services/parking.png")}
            alt="Car"
            className={classes.mainImg}
          />
          <br />
          <br />
          <h4>Safety and Security Systems</h4>
          We offer a range of safety and security systems, including access
          control systems, fire alarms, and intrusion detection systems. Our
          systems are designed to protect your property and alert you to
          potential threats, giving you peace of mind and the ability to take
          action when necessary.
          <br />
          <br />
          At Eagle Eye Worldwide, we are committed to providing our clients with
          the best possible service and support for their electronic gadgets and
          security solutions. Our team of experts can help you select the right
          products for your needs and provide installation, repair, and
          maintenance services to keep your systems functioning at their best.
          Contact us today to learn more about how we can help you enhance the
          safety and security of your property.
        </div>
      </div>
    </div>
  );
};

export default Electronic;
