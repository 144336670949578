import React, { useRef, useState } from 'react'
import classes from './Contact.module.scss'
import { Box, Button, Divider, TextField, Typography } from '@mui/material'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import emailjs from '@emailjs/browser'
import Loader from '../../Components/Loader/Loader'

const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const form = useRef()

  const validateInputs = () => {
    const errors = {}
    if (!name.trim()) {
      errors.name = 'Name is required'
    }
    if (!email.trim()) {
      errors.email = 'Email is required'
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid'
    }

    if (
      phone &&
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone)
    ) {
      errors.phone = 'Phone number is invalid'
    }
    if (!message.trim()) {
      errors.message = 'Message is required'
    }
    return errors
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const errors = validateInputs()
    if (Object.keys(errors).length === 0) {
      //here add form
      setLoading(true)
      emailjs
        .sendForm(
          'service_36rx43b',
          'template_wfr0tkt',
          form.current,
          'HxPV0rBbGFdYb8WQ7',
        )
        .then(
          (result) => {
            alert('Your message sent')
            setLoading(false)
          },
          (error) => {
            console.log('err', error)
            alert('Some error occured!')
            setLoading(false)
          },
        )

      setName('')
      setEmail('')
      setPhone('')
      setMessage('')
    } else {
      setErrors(errors)
    }
  }
  return (
    <div className={classes.contact}>
      <div className={classes.title}>Contact Us</div>
      <div className={classes.subText}>
        Need to reach us? We're just a click away. Use the form below to send us
        a message, and we'll be in touch shortly.
      </div>
      <Divider sx={{ mb: '20px' }} />
      <div className={classes.contactDetails}>
        <div className={classes.contactContainer}>
          <div className={classes.form}>
            <form className={classes.root} ref={form} onSubmit={handleSubmit}>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                variant="standard"
                value={name}
                onChange={(event) => setName(event.target.value)}
                error={!!errors.name}
                helperText={errors.name}
                InputLabelProps={{
                  style: {
                    color: '#0b1c48',
                  },
                }}
                InputProps={{
                  style: {
                    borderBottom: '1px solid #0b1c48',
                    outline: 'none',
                  },
                }}
              />
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                variant="standard"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                InputLabelProps={{
                  style: {
                    color: '#0b1c48',
                  },
                }}
                InputProps={{
                  style: {
                    borderBottom: '1px solid #0b1c48',
                    outline: 'none',
                  },
                }}
              />

              <TextField
                id="phone"
                label="Phone"
                name="phone"
                variant="standard"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                error={!!errors.phone}
                helperText={errors.phone}
                InputLabelProps={{
                  style: {
                    color: '#0b1c48',
                  },
                }}
                InputProps={{
                  style: {
                    borderBottom: '1px solid #0b1c48',
                    outline: 'none',
                  },
                }}
              />

              <TextField
                required
                id="message"
                label="Message"
                name="message"
                variant="standard"
                multiline
                rows={4}
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                error={!!errors.message}
                helperText={errors.message}
                InputLabelProps={{
                  style: {
                    color: '#0b1c48',
                  },
                }}
                InputProps={{
                  style: {
                    borderBottom: '1px solid #0b1c48',
                    outline: 'none',
                  },
                }}
              />

              <Button
                type="submit"
                sx={{
                  bgcolor: '#e47e52',
                  color: '#ffffff',
                  '&:hover': {
                    color: '#e47e52',
                    outline: '2px solid #e47e52',
                  },
                }}
              >
                Submit
              </Button>
            </form>
          </div>
          <div className={classes.details}>
            <div className={classes.detailsTitle}>Reach Us</div>
            <div>
              <Box sx={{ display: 'flex', alignItems: 'center', pb: '8px' }}>
                <PhoneIphoneIcon sx={{ mr: '8px' }} />
                <Typography>
                  <a
                    href="tel:+973 36118851"
                    style={{ color: '#ffffff', textDecoration: 'none' }}
                  >
                    {' '}
                    +973 36118851
                  </a>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', pb: '8px' }}>
                <PhoneIphoneIcon sx={{ mr: '8px' }} />
                <Typography>
                  <a
                    href="tel:+973 36118851"
                    style={{ color: '#ffffff', textDecoration: 'none' }}
                  >
                    {' '}
                    +91 9110836116
                  </a>
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', pb: '8px' }}>
                <EmailIcon sx={{ mr: '8px' }} />
                <Typography>
                  <a
                    href="mailto:eagleeyeinfoworld@gmail.com"
                    style={{ color: '#ffffff', textDecoration: 'none' }}
                  >
                    {' '}
                    eagleeyeinfoworld@gmail.com
                  </a>
                </Typography>
              </Box>
              {/* <Box sx={{ display: 'flex', alignItems: 'center', pb: '8px' }}>
                <EmailIcon sx={{ mr: '8px' }} />
                <Typography>
                  <a
                    href="mailto:vigneshshettigar39@gmail.com"
                    style={{ color: '#ffffff', textDecoration: 'none' }}
                  >
                    {' '}
                    vigneshshettigar39@gmail.com
                  </a>
                </Typography>
              </Box> */}

              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <LocationOnIcon sx={{ mr: '8px' }} />
                <Typography>
                  Flat/shop no.0, bldg 873, <br />
                  Road 527, Block 905, <br />
                  Riffa/Alshargi
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <img
                  src={require('../../Assets/Images/QRCODE.png')}
                  alt="QR CODE"
                  height={165}
                  style={{ paddingLeft: '30px' }}
                />
              </Box>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  )
}

export default Contact
