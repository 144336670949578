import React from "react";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";

const PDF = (props) => {
  const handleDownloadPDF = () => {
    const file = require(`../Assets/Pdf/${props.path}`);
    saveAs(file, props.name);
  };

  return (
    <Button
      sx={{
        float: "right",
        bgcolor: "#0b1c48",
        color: "#ffffff",
        display: "flex",
        alignItems: "center",
        p: "10px 20px",
        "&:hover": {
          bgcolor: "#e47e52",
        },
      }}
      onClick={handleDownloadPDF}
    >
      {/* <Download
        sx={{
          mr: '10px',
        }}
      /> */}
      Brochure {props.title && `(${props.title})`}
    </Button>
  );
};

export default PDF;
