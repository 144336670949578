import React from "react";
import classes from "../Services.module.scss";

const ElectricalEngg = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>
        {" "}
        MEP (Mechanical, Electrical, and Plumbing) services
      </div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/electrical.jpeg")}
            alt="Electrical"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we specialize in providing top-quality MEP
          services for a wide range of commercial, industrial, and residential
          projects. Our team of experts has years of experience in the industry,
          and we're committed to delivering exceptional products and services to
          our clients.
          <br />
          <br />
          <h4>Mechanical Services</h4>
          Our mechanical services include heating, ventilation, and air
          conditioning (HVAC) systems, refrigeration systems, and plumbing
          systems. We use state-of-the-art technology and equipment to design,
          install, and maintain efficient and reliable mechanical systems that
          meet your project's specific needs.
          <br />
          <br />
          <h4>Electrical Services</h4>
          Our electrical services include power distribution systems, lighting
          systems, fire alarm systems, and low voltage systems. Our team of
          experienced electricians will work with you to design and install
          electrical systems that are safe, reliable, and efficient.
          <img
            src={require("../../../Assets/Images/Services/Services/electrician.jpeg")}
            alt="Electrician"
            className={classes.mainImg}
          />
          <br />
          <br />
          <h4>Plumbing Services</h4>
          Our plumbing services include the design and installation of piping
          systems, drainage systems, and water supply systems. Our team of
          experienced plumbers will ensure that your plumbing systems meet all
          relevant codes and regulations and are reliable and efficient.
          <br />
          <br />
          <h4>Energy Efficiency Solutions</h4>
          At Eagle Eye Worldwide, we're committed to helping our clients reduce
          their energy consumption and save money on their utility bills. We
          offer a range of energy efficiency solutions, including building
          automation systems, LED lighting upgrades, and high-efficiency HVAC
          systems.
          <br />
          <br />
          <h4>Maintenance and Repair Services</h4>
          Our team of experts is available to provide ongoing maintenance and
          repair services for all MEP systems. We offer preventive maintenance
          plans to ensure that your systems are functioning properly and
          efficiently, as well as emergency repair services to address any
          issues that may arise.
          <br />
          <br />
          At Eagle Eye Worldwide, we're committed to providing our clients with
          the highest level of customer service and support. We'll work closely
          with you throughout the entire process to ensure that your MEP systems
          meet your needs and exceed your expectations.
        </div>
      </div>
    </div>
  );
};

export default ElectricalEngg;
