import React from "react";
import classes from "../Services.module.scss";

const Flexi = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Flexi Underground Solutions</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/flexiUG.jpeg")}
            alt="cctv"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we offer a wide range of Flexi underground
          solutions products to help our clients meet their needs for durable,
          efficient, and cost-effective underground infrastructure. Our products
          are designed and manufactured to meet the highest standards of quality
          and durability, ensuring that they can withstand even the most
          challenging underground conditions.
          <br />
          <br />
          <h4>Flexi Pipes</h4>
          Our high-quality Flexi pipes are designed to provide maximum strength
          and durability, while also being lightweight and easy to install. They
          are made from high-density polyethylene (HDPE) and are available in a
          range of sizes to meet the specific needs of each project.
          <br />
          <br />
          <h4>Flexi Manholes</h4>
          Our Flexi manholes are designed to provide a reliable and
          cost-effective solution for underground access points. They are made
          from high-density polyethylene (HDPE) and are available in a range of
          sizes and shapes to meet the specific needs of each project.
          <br />
          <br />
          <h4>Flexi Chambers</h4>
          Our Flexi chambers are designed to provide a flexible and reliable
          solution for housing underground utilities and equipment. They are
          made from high-density polyethylene (HDPE) and are available in a
          range of sizes and configurations to meet the specific needs of each
          project.
          <br />
          <br />
          <h4>Flexi Tanks</h4>
          Our Flexi tanks are designed to provide a cost-effective and durable
          solution for underground storage of water, fuel, or other liquids.
          They are made from high-density polyethylene (HDPE) and are available
          in a range of sizes and configurations to meet the specific needs of
          each project.
          <br />
          <br />
          At Eagle Eye Worldwide, we are committed to providing our clients with
          the highest level of service and support. Our team of experts has
          years of experience in designing and installing underground
          infrastructure, and we work closely with each of our clients to
          understand their unique needs and develop customized solutions that
          meet their goals and exceed their expectations.
        </div>
      </div>
    </div>
  );
};

export default Flexi;
