import React from "react";
import classes from "../Services.module.scss";

const Solar = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Solar Services</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/solar.jpeg")}
            alt="Solar"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we are committed to providing sustainable and
          eco-friendly energy solutions to our clients. That's why we offer a
          comprehensive range of solar services, including solar panel
          installation, maintenance, and repair.
          <br />
          <br />
          <h4>Solar Panel Installation</h4>
          We offer customized solar panel installation services for both
          residential and commercial properties. Our team of experts will assess
          your property and design a solar panel system that meets your energy
          needs and budget.
          <br />
          <br />
          <h4>Solar Panel Maintenance</h4>
          We offer regular maintenance services to ensure that your solar panels
          are functioning at their best. Our maintenance services include
          cleaning, inspection, and repair of your solar panel system.
          <br />
          <br />
          <h4>Solar Panel Repair</h4>
          If your solar panels are not working correctly, our team of experts
          can diagnose and repair the issue quickly and efficiently. We use the
          latest tools and equipment to ensure that your solar panels are
          functioning at their best.
          <br />
          <br />
          At Eagle Eye Worldwide, we believe in the power of sustainable energy
          solutions. That's why we are dedicated to helping our clients make the
          switch to solar energy. Our team of experts is here to provide you
          with the support and services you need to make the transition to clean
          and renewable energy. Contact us today to learn more about how we can
          help you save money and reduce your carbon footprint with our solar
          services.
        </div>
      </div>
    </div>
  );
};

export default Solar;
