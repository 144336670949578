import React from "react";
import classes from "../Services.module.scss";
import PDF from "../../../Utils/PDF";

const KidsPlay = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Kid's Playground Equipments</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/playground.jpeg")}
            alt="Playground"
            className={classes.mainImg}
            style={{ maxHeight: "500px" }}
          />
          At Eagle Eye Worldwide, we specialize in providing high-quality and
          safe playground equipment for children of all ages. We understand the
          importance of play in child development, and we're committed to
          providing our clients with the best products on the market.
          <br />
          <br />
          <h4>Wide Range of Equipment</h4>
          We offer a wide range of kids playground equipment to fit any space
          and budget. From traditional swings and slides to more adventurous
          climbers and play structures, we have everything you need to create a
          fun and engaging play area for children.
          <br />
          <br />
          <h4>Custom Solutions</h4>
          We believe that every playground is unique, and we offer custom
          solutions to fit your specific needs. Our team of experts will work
          with you to design a playground that meets your space, budget, and
          safety requirements, while also providing a fun and stimulating
          environment for children.
          <img
            src={require("../../../Assets/Images/Services/Services/playground2.jpeg")}
            alt="Playground"
            className={classes.mainImg}
            style={{ maxHeight: "500px" }}
          />
          <br />
          <br />
          <h4>Safety and Quality</h4>
          Safety is our top priority, and we only work with manufacturers who
          meet the highest safety standards in the industry. Our playground
          equipment is made from high-quality materials and designed to
          withstand years of use and abuse.
          <br />
          <br />
          <h4>Installation and Maintenance</h4>
          Our team of experts will handle all aspects of installation, including
          delivery, assembly, and testing to ensure that your playground
          equipment is installed correctly and functioning properly. We also
          offer maintenance services to keep your equipment in top condition and
          ensure its longevity.
          <br />
          <br />
          At Eagle Eye Worldwide, we're committed to providing our clients with
          the highest level of customer service and support. We'll work closely
          with you throughout the entire process to ensure that your playground
          equipment meets your needs and exceeds your expectations.
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          float: "right",
          marginTop: "56px",
        }}
      >
        <PDF
          path="EagleEye-Kids Playground.pdf"
          name="Eagle Eye Worldwide - Kid's Playground"
        />
      </div>
    </div>
  );
};

export default KidsPlay;
