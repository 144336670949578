import React from "react";
import classes from "../Services.module.scss";

const KidsRoom = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>
        Specialized Kid's Interior Decoration
      </div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/kids.jpeg")}
            alt="Playground"
            className={classes.mainImg}
            style={{ maxHeight: "500px" }}
          />
          At Eagle Eye Worldwide, we understand that designing a child's room is
          not just about picking colors and furniture. It's about creating a
          space that stimulates their imagination, encourages their creativity,
          and makes them feel safe and comfortable.
          <br />
          <br />
          Our team of experienced designers specializes in creating unique and
          personalized spaces that cater to your child's interests and needs.
          From whimsical themes to modern designs, we work closely with you to
          create a space that reflects your child's personality and style.
          <br />
          <br />
          <h4>Room Design and Planning</h4>
          We start by understanding your child's needs and preferences and work
          on creating a design that suits them best. We take into consideration
          the layout, lighting, storage, and furniture placement, among other
          factors.
          <br />
          <br />
          <h4>Color Selection and Paint</h4>
          Colors play a crucial role in creating a mood and atmosphere in a
          room. We help you choose the perfect color palette that is both
          soothing and stimulating.
          <img
            src={require("../../../Assets/Images/Services/Services/kids2.png")}
            alt="Playground"
            className={classes.mainImg}
            style={{ maxHeight: "500px" }}
          />
          <br />
          <br />
          <h4>Wall Decorations and Murals</h4>
          We can create beautiful murals, wallpapers, and decals to add interest
          and texture to the walls. From cartoon characters to nature scenes, we
          can bring any theme to life.
          <br />
          <br />
          <h4>Furniture Selection and Placement</h4>
          We help you choose the right furniture that is both functional and
          visually appealing. We take into account the child's age, size, and
          activities, to ensure that they are safe and comfortable.
          <br />
          <br />
          <h4>Accessories and Decor</h4>
          We add the finishing touches to the room by selecting the right
          accessories and decor. From lighting to bedding, rugs, and curtains,
          we ensure that every element in the room is cohesive and beautiful.
          <br />
          <br />
          At Eagle Eye Worldwide, we pride ourselves on our attention to detail
          and our commitment to creating beautiful and imaginative spaces for
          children. Contact us today to schedule a consultation and let us help
          you create a dream room for your little ones.
        </div>
      </div>
    </div>
  );
};

export default KidsRoom;
