import * as React from 'react';
import Box from '@mui/material/Box';
import MediaCard from '../Card/Card';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

export default function SimplePaper(props) {
  const [visibleCards, setVisibleCards] = React.useState(5);
  const cardListRef = React.useRef(null);

  const handleShowMore = () => {
    setVisibleCards(visibleCards + 5);
  };

  const handleShowLess = () => {
    setVisibleCards(5);
    handleScrollToTop();
  };

  const handleScrollToTop = () => {
    const targetScrollPosition = cardListRef.current.offsetTop;
    window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
  };

  const { list = [] } = props;
  const navigate = useNavigate('');
  return (
    <div ref={cardListRef}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          mt: '38px',
          mb: '100px',
          '& > :not(style)': {
            m: 1,
          },
        }}
      >
        {list.slice(0, visibleCards).map((item) => (
          <div
            onClick={() => {
              navigate(`services/${item.path}`);
            }}
          >
            <MediaCard item={item} />
          </div>
        ))}
      </Box>
      <div
        style={{
          position: 'relative',
          top: '-54px',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '54px',
        }}
      >
        {visibleCards < list.length ? (
          <div style={{ position: 'relative' }}>
            <Button
              onClick={handleShowMore}
              sx={{
                bgcolor: '#0b1c48',
                color: '#ffffff',
                p: '10px 20px',
                '&:hover': {
                  color: '#e47e52',
                },
              }}
            >
              Show More
            </Button>
          </div>
        ) : (
          <Button
            onClick={handleShowLess}
            sx={{
              bgcolor: '#0b1c48',
              color: '#ffffff',
              p: '10px 20px',
              '&:hover': {
                color: '#e47e52',
              },
            }}
          >
            Show Less
          </Button>
        )}
      </div>
    </div>
  );
}
